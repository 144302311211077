import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 导入语言文件
import ar from '@/utils/lang/ar.json'
import zh from '@/utils/lang/cn.json'
import de from '@/utils/lang/de.json'
import en from '@/utils/lang/en.json'
import es from '@/utils/lang/es.json'
import fr from '@/utils/lang/fr.json'
import hi from '@/utils/lang/hi.json'
import ja from '@/utils/lang/ja.json'
import ko from '@/utils/lang/ko.json'
import pt from '@/utils/lang/pt.json'
import ru from '@/utils/lang/ru.json'

Vue.use(VueI18n)

const messages = {
	ar,
	zh,
	de,
	en,
	es,
	fr,
	hi,
	ja,
	ko,
	pt,
	ru,
}

const i18n = new VueI18n({
	locale: 'en', // 设置默认语言
	messages,
})

export default i18n
