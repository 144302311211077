import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/privacy-policy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/terms-of-service',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	{
		path: '/answer',
		name: 'answer',
		component: () => import('../pages/answer.vue'),
	},
	{
		path: '/q&a',
		name: 'qa',
		component: () => import('../pages/qa.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		component: () => import('../pages/blogs.vue'),
	},
	{
		path: '/blogs/open-the-book-of-answers-to-resolve-your-doubts',
		name: 'blog1',
		component: () => import('../pages/blog1.vue'),
	},
	{
		path: "/blogs/you-think-it's-normal-but-it-could-be-long-term-anxiety-9-signs",
		name: 'blog2',
		component: () => import('../pages/blog2.vue'),
	},
	{
		path: '/blogs/4-steps-to-cure-your-decision-paralysis',
		name: 'blog3',
		component: () => import('../pages/blog3.vue'),
	},
	{
		path: '/blogs/stress-management-guide-5-scientific-methods-to-help-you-relieve-stress',
		name: 'blog4',
		component: () => import('../pages/blog4.vue'),
	},
]

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
