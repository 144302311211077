import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import createRouter from './router'
import createStore from './store'
const placeholderimage = require('@/assets/placeholder.png')
import VueLazyload from 'vue-lazyload'
import global from '@/utils/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
import VueMeta from 'vue-meta'

Vue.prototype.$global = global
Vue.use(VueMeta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: placeholderimage,
	loading: placeholderimage,
	attempt: 1,
})
const defaultFireBase = {
	apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
	authDomain: 'webs-58a8d.firebaseapp.com',
	projectId: 'webs-58a8d',
	storageBucket: 'webs-58a8d.appspot.com',
	messagingSenderId: '730684174767',
	appId: '1:730684174767:web:6e76f7ee868ece100c3f5a',
	measurementId: 'G-CRPMG7Q4EJ',
}

function initializeFirebase() {
	const firebaseConfig = window.__INITIAL_STATE__ ? window.__INITIAL_STATE__.firebaseConfig : defaultFireBase
	let app = initializeApp(firebaseConfig)
	let analytics = getAnalytics(app)
	return analytics
}

function setupAnalytics() {
	// // 此代码块只在客户端执行
	isSupported().then((result) => {
		if (result) {
			const analytics = initializeFirebase()
			logEvent(analytics, 'in_page')
			console.log('in_page')
			// app.js 中的全局日志功能
			Vue.prototype.$logEvent = (event, params = {}) => {
				console.log(event)
				logEvent(analytics, event, params)
			}
			Vue.prototype.$eventrack = (msg, method, map = {}) => {
				let params = {
					time: new Date(),
					message: msg,
					method: method,
					...map,
				}
				// console.log(params)
				logEvent(analytics, msg, params)
			}
		} else {
			// 如果 Firebase Analytics 不支持
			console.log('Firebase Analytics not support')
			Vue.prototype.$logEvent = (event, params = {}) => {
				console.log(`Analytics not supported: ${event}`, params)
			}
			Vue.prototype.$eventrack = (event, params = {}) => {
				console.log(`Analytics not supported: ${event}`, params)
			}
		}
	})
}

if (typeof window !== 'undefined') {
	setupAnalytics()
} else {
	// 对于服务器端，你可以定义一个简单的函数或者不做任何事情
	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
	Vue.prototype.$eventrack = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
}

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	const app = new Vue({
		i18n,
		router,
		store,
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
